/*
* Template Name: The Tattooist
* Template Description: Tattoo Studio Template
* Template URI: http://demo.webisir.com/the-tattooist
* Version: 1.2
* Author: Webisir
* Author URI: http://themeforest.net/user/webisir
*/

/*--------------------------------------------------------------
    Custom CSS
----------------------------------------------------------------



/* Styles for the bouncing arrow on home page  */
.bounce{
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
}
.arrow-down {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 15%;
    z-index: 100;
    bottom: 0;
    color: #fff;
    opacity: 0.5;
    cursor: pointer;
    background: url('../img/arrow-down.png');
}

.switch-language{
    position: absolute;
    color: white;
    z-index: 9999;
    right: 20px;
}

.switch-language>a{
    padding: 0 15px !important;
}

.bg-overlay{
    opacity: 0.3;
}

div.section.catalog {
    border-top: 1px solid #BDC3C7;
}

#nav-menu ul a {
    font-size: 14px;
}

#footer-map {
    height: 269px;
}

.scrolloff {
    pointer-events: none;
}
#canvas1 {
    height: 100%;
}